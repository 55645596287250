<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="Certificate Format Lists" no-body>
          <b-card-body>
            <b-row class="justify-content-between">
              <b-col
                  cols="12"
                  md="2"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label class="mr-1">Show</label>
                <Select
                    @on-change="paginateChange"
                    v-model="search.paginate"
                    :clearable="false"
                >
                <Option :value="10"> 10</Option>
                <Option :value="30"> 30</Option>
                <Option :value="50"> 50</Option>
                <Option :value="80"> 80</Option>
                <Option :value="100"> 100</Option>
                </Select>
              </b-col>
              <b-col cols="12" md="3">
                <Select
                    v-model="search.status"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select Status"
                >
                <Option :value="1"> Active</Option>
                <Option :value="2"> Inactive</Option>
                </Select>
              </b-col>
              <b-col cols="12" md="3">
                <Select
                    v-model="search.is_header"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select Header"
                >
                <Option :value="1"> With Header</Option>
                <Option :value="2"> Without Header</Option>
                </Select>
              </b-col>
              <b-col cols="12" md="4">
                <Input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search name..."
                    type="text"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="text-center">Sl</th>
                <th>Background</th>
                <th>Name</th>
                <th
                    v-if="permission.edit || permission.update_status"
                    class="text-center"
                >
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(format, index) in formats.data"
                  :key="index"
                  v-if="formats.data.length"
              >
                <td class="align-middle text-center">
                  {{ formats.meta.from + index }}
                </td>
                <td>
                  <img :src="format.background_file" width="100" class="rounded"/>
                </td>
                <td>
                  <p class="mb-0">
                    <b>{{ format.name }}</b>
                  </p>
                  <p class="mb-0"><b>Created by:</b> {{ format.created_by_name }}</p>
                  <p class="mb-0">
                    <b>Background Name : </b> {{ format.background_name }}
                  </p>
                  <p class="mb-0">
                      <span
                          style="margin-right: 3px"
                          class="badge"
                          :class="
                        format.status == 'Active'
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                      >
                      {{ format.status }}
                    </span>
                    <span
                        class="badge"
                        :class="
                        format.is_header == 'With header'
                          ? 'badge-success'
                          : 'badge-danger'
                        "
                    >
                        {{ format.is_header }}
                      </span>
                  </p>
                </td>


                <td
                    class="align-middle text-center"
                    v-if="permission.edit || permission.update_status"
                >
                  <Tooltip content = "Edit" placement = "top-end">
                    <button
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        @click="Edit(format.id)"
                    >
                      <feather-icon icon="EditIcon" size="16"/>
                    </button>
                  </Tooltip>
                  <Tooltip content = "Status update" placement = "top-end">
                    <button
                        v-if="permission.update_status"
                        class="btn btn-success btn-sm"
                        style="margin-left: 5px"
                        @click="statusUpdate(format.id)"
                    >
                      <feather-icon icon="CheckCircleIcon" size="16"/>
                    </button>
                  </Tooltip>
                  <Tooltip content = "View" placement = "top-end">
                    <router-link
                        v-if="permission.attach_manage"
                        class="btn btn-primary btn-sm"
                        :to="'/single/certificate/format/' + format.id"
                        style="margin-left: 5px"
                    >
                      <feather-icon icon="EyeIcon" size="16"/>
                    </router-link>
                  </Tooltip>
                  <Tooltip content = "Generate" placement = "top-end">
                    <router-link
                        v-if="permission.attach_manage"
                        class="btn btn-primary btn-sm"
                        :to="'/certificate/generator/' + format.id"
                        style="margin-left: 5px"
                    >
                      <feather-icon icon="ListIcon" size="16"/>
                    </router-link>
                  </Tooltip>
                </td>
              </tr>
              <tr v-if="!formats.data.length" class="text-center">
                <h3>No data found</h3>
              </tr>
              </tbody>
            </table>
          </div>
          <b-card-body>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-muted"
              >Showing {{ formats.meta ? formats.meta.from : 0 }} to
                {{ formats.meta ? formats.meta.to : 0 }} of
                {{ formats.meta ? formats.meta.total : 0 }} entries</span
              >
              <pagination
                  :data="formats"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
              ></pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != null">
        <b-card
            :title="'certificate Format ' + (!form.id ? 'Add' : 'Update')"
        >
          <b-form @submit.prevent="!form.id ? Create() : Update(form.id)">
            <b-row class="justify-content-center">
              <b-col cols="12">
                <div class="form-group">
                  <label> Name </label>
                  <Input
                      v-model="form.name"
                      placeholder="Enter Certificate Name"
                      style="width: 100%"
                      type="text"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group">
                  <label>Select background</label>
                  <Select v-model="background_index" filterable @on-change="getBackgroundImage()">
                  <Option
                      v-for="(background, index) in backgrounds"
                      :value="index"
                      :key="background.id"
                  >{{ background.name }}
                  </Option
                  >
                  </Select>
                </div>
                <div class="form-group">
                  <label> Background </label>

                  <div class="div p-2 border">
                    <img
                        :src="this.file ? this.file : getImage(160, 100)"
                        width="100%"
                        class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </div>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center mt-2">
                  <div>
                    <div>
                      <label> Header (with/without) </label>
                    </div>
                    <div>
                      <div>
                        <b-form-checkbox
                            name="status"
                            style="margin-top: 5px"
                            v-model="form.is_header"
                            switch
                            inline
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <b-button
                        variant="transparent"
                        class="border text-danger"
                        @click="Clear"
                    >
                      Clear
                    </b-button>
                    <b-button
                        variant="primary"
                        type="submit"
                        :disabled="form.busy"
                        class="ml-1"
                    >
                      {{ form.id ? "Update" : "Create" }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        is_header: true,
        status: true,
        background_id: null,
        _method: "POST",
      }),
      file: null,
      background_index: null,
      backgrounds: {},
      search: {
        search_data: "",
        status: "",
        is_header: "",
        paginate: 10,
        page: 1,
      },
      formats: {},
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    BFormFile,
  },
  mounted() {
    this.getActiveBackground();
    this.getResults();
  },
  methods: {
    getBackgroundImage() {
      this.form.background_id = this.backgrounds[this.background_index].id;
      this.file = this.$store.state.base_url + this.backgrounds[this.background_index].file;
    },

    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
          .get(`/app/certificate-format${queryParams}`)
          .then((res) => {
            this.formats = res.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.is_header = true;
      this.form.status = true;
      this.form.background_id = null;
      this.background_index = null;
      this.file = "";
      this.form._method = "POST";
    },

    Create() {
      this.form
          .post("/app/certificate-format")
          .then((res) => {
            this.getResults();
            this.Clear();
            this.s(res.data.message);
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    Update(id) {
      this.form
          .put("/app/certificate-format/" + id)
          .then((res) => {
            this.getResults();
            this.Clear();
            this.s(res.data.message);
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    Edit(id) {
      axios
          .get("app/certificate-format/" + id + "/edit")
          .then((res) => {
            this.form.id = res.data.certificatesFormat.id;
            this.form.name = res.data.certificatesFormat.name;
            this.form.is_header = res.data.certificatesFormat.is_header == true ? true : false;
            this.form.status = res.data.certificatesFormat.status == true ? true : false;
            this.form.background_id = res.data.certificatesFormat.background_id;
            this.background_index = this.backgrounds.findIndex(background => background.id == this.form.background_id);
            this.file = this.$store.state.base_url + this.backgrounds[this.background_index].file;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    getActiveBackground() {
      axios
          .get("/app/get/all/certificate/background")
          .then((res) => {
            this.backgrounds = res.data.backgrounds;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    statusUpdate(id) {
      Swal.fire({
        title: "Are you sure to change the status?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
              .put("/app/certificate-format/update-status/" + id)
              .then((res) => {
                this.getResults();
                this.Clear();
                this.s(res.data.message);
              })
              .catch((e) => {
                this.e(e.response.data.message);
              });
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.format;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
